import { render, staticRenderFns } from "./SideMenu.vue?vue&type=template&id=f0895caa&scoped=true&"
import script from "./SideMenu.vue?vue&type=script&lang=ts&"
export * from "./SideMenu.vue?vue&type=script&lang=ts&"
import style0 from "./SideMenu.vue?vue&type=style&index=0&id=f0895caa&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f0895caa",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Logo: require('/home/circleci/project/components/Logo.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
installComponents(component, {VBadge,VIcon,VList,VListItem,VListItemContent,VListItemIcon,VListItemTitle,VNavigationDrawer})

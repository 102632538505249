import axios from 'axios'

import { RidaApiService } from './RidaApi'
import {
  V1RidaApiGetFileUploadCheckedImageResponse,
  V1RidaApiGetFileUploadUrlResponse,
} from './Type'
import { Dayjs } from 'dayjs'
import { DEFAULT_FORMAT } from './Constant'

class FileUpload {
  public static async getFileUploadPresignedUrl(data: {
    taskId?: string
    userId?: string
    documentTypeId: number
    extension: string
  }) {
    const response =
      await RidaApiService.get<V1RidaApiGetFileUploadUrlResponse>(
        'v1/fileUpload/url',
        {
          task_id: data.taskId,
          document_type_id: data.documentTypeId,
          file_extension: data.extension,
          user_id: data.userId,
        },
      )

    return response.data.data
  }

  public static async confirmFileUploadSuccess(fileUploadIds: string[]) {
    await RidaApiService.put('v1/fileUpload/confirm', {
      ids: fileUploadIds,
    })
  }

  public static async uploadPresignedUrl(
    url: string,
    data: Blob,
    contentType: string,
  ) {
    await axios.put(url, data, {
      headers: {
        'Content-Type': contentType,
      },
    })
  }

  public static async getFileUploadCheckedImages(
    date: Dayjs,
    filter: {
      itemsPerPage: number
      pageNumber: number
      isReviewedByOps: boolean
    },
  ) {
    const r =
      await RidaApiService.get<V1RidaApiGetFileUploadCheckedImageResponse>(
        'v1/fileUpload/checkedImage',
        {
          items_per_page: filter.itemsPerPage,
          page_number: filter.pageNumber,
          is_reviewed_by_ops: filter.isReviewedByOps,
          date: date.format(DEFAULT_FORMAT.DATE),
        },
      )

    return r.data.data
  }

  public static async updateFileUploadCheckedImage(
    fileUploadIds: string[],
    isImageDetailsSufficient: boolean,
    isReviewedByOps: boolean,
  ) {
    await RidaApiService.put('v1/fileUpload/checkedImage', {
      ids: fileUploadIds,
      is_image_details_sufficient: isImageDetailsSufficient,
      is_reviewed_by_ops: isReviewedByOps,
    })
  }
}

export { FileUpload as FileUploadService }

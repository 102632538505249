import { Dayjs } from 'dayjs'

import { DEFAULT_FORMAT } from './Constant'
import { RidaApiService } from './RidaApi'
import {
  V1RidaApiGetLateJobResponse,
  V1RidaApiGetReportEarningForDateRangeResponse,
  V1RidaApiGetReportJobPerDayTimelineResponse,
} from './Type'

class Report {
  public static async generateEarningForDateRange(
    startDate: Dayjs,
    endDate: Dayjs,
  ) {
    const response =
      await RidaApiService.get<V1RidaApiGetReportEarningForDateRangeResponse>(
        'v1/report/earningForDateRange',
        {
          start_date: startDate.format(DEFAULT_FORMAT.DATE),
          end_date: endDate.format(DEFAULT_FORMAT.DATE),
        },
      )

    return response.data.data
  }

  public static async getLateJob(startDate: Dayjs, endDate: Dayjs) {
    const response = await RidaApiService.get<V1RidaApiGetLateJobResponse>(
      'v1/report/lateJob',
      {
        start_date: startDate.format(DEFAULT_FORMAT.DATE),
        end_date: endDate.format(DEFAULT_FORMAT.DATE),
      },
    )

    return response.data.data
  }

  public static async triggerBatching(date: Dayjs) {
    await RidaApiService.post('v1/report/triggerBatching', {
      date: date.format(DEFAULT_FORMAT.DATE),
    })
  }

  public static async getJobPerUserForTimelineView(
    date: Dayjs,
    filter: {
      driverGroupIds: string[]
      merchantGroupIds: string[]
    },
  ) {
    const response =
      await RidaApiService.get<V1RidaApiGetReportJobPerDayTimelineResponse>(
        'v1/report/jobPerDayTimeline',
        {
          date: date.format(DEFAULT_FORMAT.DATE),
          driver_group_id: filter.driverGroupIds,
          merchant_group_id: filter.merchantGroupIds,
        },
      )

    return response.data.data
  }
}

export { Report as ReportService }

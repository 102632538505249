import { Dayjs } from 'dayjs'
import _ from 'lodash'

import { DEFAULT_FORMAT } from './Constant'
import { RidaApiService } from './RidaApi'
import {
  V1RidaApiGetAidaChatAvailableDriverResponse,
  V1RidaApiPostAidaChatOptimizeRouteResponse,
} from './Type'

class AidaChat {
  public static async getAvailableDrivers(
    date: Dayjs,
    merchantGroupId: string,
  ) {
    const response =
      await RidaApiService.get<V1RidaApiGetAidaChatAvailableDriverResponse>(
        'v1/aidaChat/availableDriver',
        {
          date: date.format(DEFAULT_FORMAT.DATE),
          merchant_group_id: merchantGroupId,
        },
      )

    return response.data.data
  }

  public static async publishRoutes(routeData: {
    groupId: string
    date: Dayjs
    routes: {
      driverId: string | null
      vehicleType: string
      pickupTime: string
      stops: {
        type: string
        address1: string
        address2: string
        location: {
          lat: string
          lng: string
        }
        postalCode: string
        name: string
        phone: string
        window: string
        items: {
          category: string
          customId: string
          notes: string
          size: number
        }[]
      }[]
    }[]
  }) {
    await RidaApiService.post('v1/aidaChat/publishRoute', {
      group_id: routeData.groupId,
      date: routeData.date.format(DEFAULT_FORMAT.DATE),
      routes: _.map(routeData.routes, r => {
        return {
          driver_id: r.driverId,
          vehicle_type: r.vehicleType,
          pickup_time: r.pickupTime,
          stops: _.map(r.stops, st => {
            return {
              type: st.type,
              address1: st.address1,
              address2: st.address2,
              location: {
                lat: st.location.lat,
                lng: st.location.lng,
              },
              postal_code: st.postalCode,
              name: st.name,
              phone: `+65${st.phone}`,
              window: st.window,
              items: _.map(st.items, i => {
                return {
                  category: i.category,
                  custom_id: i.customId,
                  notes: i.notes,
                  size: i.size,
                }
              }),
            }
          }),
        }
      }),
    })
  }

  public static async optimizeRoute(routeData: {
    stops: {
      id: string
      location: {
        lat: string
        lng: string
      }
      stopTypeId: number
    }[]
  }) {
    const r =
      await RidaApiService.post<V1RidaApiPostAidaChatOptimizeRouteResponse>(
        'v1/aidaChat/optimizeRoute',
        {
          stops: _.map(routeData.stops, st => {
            return {
              id: st.id,
              location: {
                lat: st.location.lat,
                lng: st.location.lng,
              },
              stop_type_id: st.stopTypeId,
            }
          }),
        },
      )

    return r.data.data
  }
}

export { AidaChat as AidaChatService }

import { RidaApiService } from './RidaApi'
import { V1RidaApiGetJobIdCommentResponse } from './Type'

class Comment {
  public static async getAllCommentsForJob(jobId: string) {
    const response = await RidaApiService.get<V1RidaApiGetJobIdCommentResponse>(
      `v1/job/${jobId}/comment`,
    )

    return response.data.data
  }

  public static async createCommentForJob(
    jobId: string,
    commentData: { description: string },
  ) {
    await RidaApiService.post(`v1/job/${jobId}/comment`, {
      description: commentData.description,
    })
  }

  public static async deleteComment(commentId: string) {
    await RidaApiService.delete(`v1/comment/${commentId}`)
  }
}

export { Comment as CommentService }
